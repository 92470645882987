import { faChartSimple, faTable } from "@fortawesome/free-solid-svg-icons";
import { IonButton } from "@ionic/react";
import { FaIcon } from "@/components/icons/FaIcon/FaIcon";
import "./reportSectionHeaderViewToggle.scss";

type ReportSectionHeaderViewToggleProps = {
    view: View;
    onViewChange: (view: View) => void;
};

export function ReportSectionHeaderViewToggle(props: ReportSectionHeaderViewToggleProps) {
    return (
        <div className="report-section-header-view-toggle">
            <span className="report-section-header-view-toggle__label">Ansicht</span>

            <IonButton
                className="report-section-header-view-toggle__button"
                color={props.view === "chart" ? "primary" : "light"}
                onClick={() => props.onViewChange("chart")}>
                <FaIcon icon={faChartSimple} spacing="right" />
                Chart
            </IonButton>
            <IonButton
                className="report-section-header-view-toggle__button"
                color={props.view === "table" ? "primary" : "light"}
                onClick={() => props.onViewChange("table")}>
                <FaIcon icon={faTable} spacing="right" />
                Tabelle
            </IonButton>
        </div>
    );
}

type View = "chart" | "table";
