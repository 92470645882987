import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton } from "@ionic/react";
import clsx from "clsx";
import { ReactNode, useEffect, useRef, useState } from "react";
import * as FaPopover from "@/components/FaPopover";
import { ReportSectionHeaderConfigurationResetButton } from "./ReportSectionHeaderConfigurationResetButton/ReportSectionHeaderConfigurationResetButton";
import "./reportSectionHeaderConfigurationPopover.scss";

type ReportSectionHeaderConfigurationPopoverProps = {
    children: ReactNode;

    classNames?: ReportSectionHeaderConfigurationPopoverClassNames;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;

    /**
     * If set to `false` indicates that the configuration is not the default
     * @default true
     */
    isDefaultConfiguration?: boolean;

    onResetConfiguration?: () => void;
};

export function ReportSectionHeaderConfigurationPopover(props: ReportSectionHeaderConfigurationPopoverProps) {
    const { isDefaultConfiguration = true, ...restProps } = props;

    const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
    const open = props.open || uncontrolledOpen;
    const contentRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    const [overlayVisible, setOverlayVisible] = useState<boolean | undefined>();

    // REFACTOR: See MonthPicker.tsx why this effect is required
    useEffect(() => {
        if (!open) {
            return;
        }

        const openListener = () => {
            if (contentRef.current) {
                contentRef.current.style.display = "none";
            }
            if (overlayRef.current) {
                setOverlayVisible(false);
            }
        };
        const closeListener = () => {
            if (contentRef.current) {
                contentRef.current.style.display = "flex";
            }
            if (overlayRef.current) {
                setOverlayVisible(undefined);
            }
        };

        document.addEventListener("ion-modal-opened", openListener);
        document.addEventListener("ion-modal-closed", closeListener);
        return () => {
            document.removeEventListener("ion-modal-opened", openListener);
            document.removeEventListener("ion-modal-closed", closeListener);
        };
    }, [open]);

    const handleResetClick = () => {
        restProps.onResetConfiguration?.();
        setUncontrolledOpen(false);
    };

    const showResetButton = !isDefaultConfiguration && !!restProps.onResetConfiguration;

    return (
        <FaPopover.Root
            controlOverlayVisible={overlayVisible}
            open={open}
            onOpenChange={open => {
                props.onOpenChange?.(open);
                setUncontrolledOpen(open);
            }}
            ref={overlayRef}>
            <FaPopover.Trigger>
                <IonButton
                    color="medium"
                    className={clsx(
                        "report-section-header-configuration-popover__trigger",
                        restProps.classNames?.trigger
                    )}>
                    {!isDefaultConfiguration && (
                        <span className="report-section-header-configuration-popover__changes" />
                    )}
                    <FontAwesomeIcon
                        className="report-section-header-configuration-popover__trigger-icon"
                        icon={faSliders}
                    />
                    Konfiguration
                </IonButton>
            </FaPopover.Trigger>

            <FaPopover.Content
                ref={contentRef}
                sideOffset={8}
                collisionPadding={16}
                onOpenAutoFocus={event => {
                    event.preventDefault();
                }}
                className={clsx("report-section-header-configuration-popover__content", restProps.classNames?.content)}>
                {restProps.children}

                {showResetButton && (
                    <>
                        <div className="report-section-header-configuration-popover__divider" />
                        <div className="report-section-header-configuration-popover__footer">
                            <ReportSectionHeaderConfigurationResetButton onClick={handleResetClick} />
                        </div>
                    </>
                )}
            </FaPopover.Content>
        </FaPopover.Root>
    );
}

type ReportSectionHeaderConfigurationPopoverClassNames = {
    trigger?: string;
    content?: string;
};
