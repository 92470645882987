import { ReportSectionContent } from "./ReportSectionContent/ReportSectionContent";
import { ReportSectionHeader } from "./ReportSectionHeader/ReportSectionHeader";
import { ReportSectionHeaderConfiguration } from "./ReportSectionHeader/ReportSectionHeaderConfiguration/ReportSectionHeaderConfiguration";
import { ReportSectionHeaderConfigurationPopover } from "./ReportSectionHeader/ReportSectionHeaderConfigurationPopover/ReportSectionHeaderConfigurationPopover";
import { ReportSectionHeaderRemove } from "./ReportSectionHeader/ReportSectionHeaderRemove/ReportSectionHeaderRemove";
import { ReportSectionHeaderTitle } from "./ReportSectionHeader/ReportSectionHeaderTitle/ReportSectionHeaderTitle";
import { ReportSectionHeaderViewToggle } from "./ReportSectionHeader/ReportSectionHeaderViewToggle/ReportSectionHeaderViewToggle";
import { ReportSectionRoot } from "./ReportSectionRoot/ReportSectionRoot";

export const Root = ReportSectionRoot;
export const Content = ReportSectionContent;
export const Header = ReportSectionHeader;
export const Title = ReportSectionHeaderTitle;
export const Configuration = ReportSectionHeaderConfiguration;
export const ConfigurationPopover = ReportSectionHeaderConfigurationPopover;
export const ViewToggle = ReportSectionHeaderViewToggle;
export const Remove = ReportSectionHeaderRemove;
